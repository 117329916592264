// Customizable Area Start
import React from "react";
import NotificationListController from "./NotificationListController";
import Navbar from "../../../components/src/Navbar.web";
import Footer from "../../../components/src/Footer.web";
import { Box, Typography, styled, List, ListItem, ListItemText, Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import CustomePagination from "../../../components/src/CustomPagination.web";
import { noData } from "../../postcreation/src/assets";

class NotificationList extends NotificationListController {
    render() {
        const { totalCount, currentPage, totalPages } = this.state;

        return (
            <>
            <CustomNavbar>
            <Navbar navigation={this.props.navigation} id={""} NaviagationPage={""}/>
            </CustomNavbar>
                <NavbarMobile>
                    <ArrowBackIos data-test-id='btnBack' onClick={this.handleBack} style={webStyle.backIcon} />
                    <NavbarTitle>Notifications</NavbarTitle>
                </NavbarMobile>
                <MainBox>
                    <Navigation>
                        <Typography data-test-id="navigateToHome" onClick={this.handleBack} style={webStyle.home}>Home</Typography>
                        <ArrowForwardIos style={webStyle.forwardArrow} />
                        <Typography style={webStyle.joblisting}>Notifications</Typography>
                    </Navigation>
                    <Heading>Notifications</Heading>
                    <Box style={{background:'#fff', padding:'20px',borderRadius:'20px'}}>
                        {this.state.notifications.length>0?
                        <List>
                            {this.state.paginatednNotifications.map((email: any) => (
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '8px',
                                    }}
                                >
                                    <Box style={{ width: "25%" }}>
                                        <Typography variant="caption" style={{ fontSize: "16px", fontWeight: 'bolder' }}>{email.heading}</Typography>
                                    </Box>
                                    <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1, marginLeft: '8px' }}>
                                        <ListItem key={email.id} >
                                            {email.profilePic? 
                                            <img src={email.profilePic} alt="" style={{width:'55px',height:'55px',borderRadius:'50%', marginRight:'30px'}}/>
                                            :
                                            <Avatar style={{width:'50px',height:'50px',borderRadius:'50%', marginRight:'35px'}}/>
                                            }
                                            <ListItemText
                                                primary={
                                                    <Typography component="span" variant="body1" style={{ fontWeight: 'bold' }}>
                                                        {email.name}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <>
                                                        <Typography component="span" variant="body2">
                                                            {email.message}
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    </Box>

                                    <Box>
                                        <Typography variant="caption" style={{ textAlign: 'right' }}>
                                            {email.time}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}

                        </List>
                        :
                        <>
                        <Box style={webStyle.noResultBox}>
                            <Box>
                                <img src={noData} />
                                <Typography style={webStyle.noResultText}>
                                    No data found!
                                </Typography>

                            </Box>
                        </Box>
                    </>
                        }

                    </Box>
                    {totalCount >= this.state.itemsPerPage && (
                            <CustomePagination
                                total_page={totalPages}
                                current_page={currentPage}
                                total_count={totalCount}
                                onchange={this.handlePageChange}
                            />
                        )}
                </MainBox>
                <Footer navigation={this.props.navigation} id={""} NaviagationPage={""} />
            </>
        );
    }
}

export default NotificationList;

const CustomNavbar = styled(Box)({
    "@media only screen and (max-width: 571px)": {
        display: 'none',
    }
})
const NavbarMobile = styled(Box)({
    display: 'none',
    "@media only screen and (max-width: 571px)": {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#395169',
        padding: '15px 0 15px 20px',
        position: 'relative',
    },
});

const NavbarTitle = styled(Box)({
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#FFFFFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});
const MainBox = styled(Box)({
    background: "#F8F8F8",
    position: "relative",
    padding: "140px 375px 120px 375px",
    "@media only screen and (max-width: 1330px)": {
        paddingTop: 100,
        paddingLeft: 300,
        paddingRight: 300
    },
    "@media only screen and (max-width: 1235px)": {
        paddingTop: 100,
        paddingLeft: 230,
        paddingRight: 230
    },
    "@media only screen and (max-width: 1100px)": {
        paddingTop: 100,
        paddingLeft: 185,
        paddingRight: 185
    },
    "@media only screen and (max-width: 959px)": {
        paddingTop: 100,
        padding: 100,
    },
    "@media only screen and (max-width: 664px)": {
        padding: 37,
        paddingTop: 100
    },
    "@media only screen and (max-width: 571px)": {
        padding: 0,
        background: '#fff',
        paddingTop: 20
    }
})
const Navigation = styled(Box)({
    display: "flex",
    gap: "10px",
    position: "absolute" as "absolute",
    top: 32,
    left: 114,
    "@media only screen and (max-width: 571px)": {
        display: 'none',
    }
})
const Heading = styled(Typography)({
    color: '#1E293B',
    fontFamily: "Inter",
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    marginBottom: 24,
    "@media only screen and (max-width: 571px)": {
        display: 'none',
    }
})

const webStyle = {
    backIcon: {
        color: '#fff'
    },
    joblisting: {
        color: "#1E293B",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    home: {
        color: "#475569",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    forwardArrow: {
        color: "#395169",
        height: "15px",
        marginTop: 4
    },
    box1: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px',
    },
    seconddiv: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
    },
    thirddiv: {
        backgroundColor: "#F8F8F8",
        padding: "2px 12px 2px 12px",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#000000",
        borderRadius: "2px"
    },
    fourthdiv: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#0F172A"
    },
    customdivStyle: {
        display: "flex",
        gap: "10px",
        marginTop: "12px",
        borderRadius: "2px"
    },
    ContainerStyle: {
        display: "flex",
        gap: "10px",
        marginTop: "20px"
    },
    typeStyle: {
        fontFamily: "Inter",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#4788B7"
    },
    newdiv: {
        borderTop: "1px solid #F1F5F9",
        paddingTop: '20px',
        marginTop: "35px",
        display: "flex",
        justifyContent: "space-between"
    },
    anotherdiv: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#0F172A"
    },
    Saved: {
        color: '#395169',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '22px',
        textAlign: 'left' as 'left',
    },
    popup: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        width: '332px',
        padding: '10px 10px 10px 10px',
        gap: '8px',
        borderFadius: '8px',
        boxShadow: '0px 6px 15px -3px #00000026',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#0F172A',
    },
    noResultBox: {
        background: "#fff",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center" as "center",
        alignItem: "center" as "center",
        paddingTop: 88,
        paddingBottom: 128,
    },
    noResultText: {
        color: "0F172A",
        fontWeight: 600,
        marginTop: "16px",
        fontFamily: "Inter",
        fontSize: "20px",
        lineHeight: "28px",
    },
};
// Customizable Area End