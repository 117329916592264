import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import storage from '../../../framework/src/StorageProvider';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isAccountOpen: boolean;
  isChangeEmailOpen: boolean;
  isPasswordChnageOpen: boolean;
  isDeleteOpen: boolean;
  oldEmail: string;
  newEmail: string;
  confirmEmail: string;
  oldEmailError: string;
  confirmEmailError: string;
  newEmailError: string;
  isEmailSuccess: boolean;
  isOldPassEnabled: boolean;
  isNewPassenabled: boolean;
  isConfirmPassEnabled: boolean;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  passwordConditions: {
    isCapital: boolean;
    isLowercase: boolean;
    isNumber: boolean;
    isLength: boolean;
  };
  oldPasswordError: string;
  newPasswordError: string;
  confirmPassError: string;
  isNotificationOpen: boolean;
  isSmsNotiChecked: boolean;
  isRecommendationsChecked: boolean;
  isNotificationChecked: boolean;
  isLogoutDialogopen: boolean;
  isSmsEnable:boolean;
  isSmsDisable:boolean;
  isRecomdEnable:boolean;
  isRecomdDisable:boolean;
  isPushEnable:boolean;
  isPushDisable:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  setNewEmailId: string = '';
  emailReg: RegExp;
  setNewPassId: string = '';
  deleteAccId: string = "";
  smsClickedId:string='';
  pushNotiId:string="";
  recommonedId:string='';
  getProfileDataId:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isAccountOpen: false,
      isChangeEmailOpen: false,
      isPasswordChnageOpen: false,
      isDeleteOpen: false,
      oldEmail: '',
      newEmail: '',
      confirmEmail: '',
      oldEmailError: '',
      confirmEmailError: '',
      newEmailError: '',
      isEmailSuccess: false,
      isOldPassEnabled: true,
      isNewPassenabled: true,
      isConfirmPassEnabled: true,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordConditions: {
        isCapital: false,
        isLowercase: false,
        isNumber: false,
        isLength: false,
      },
      oldPasswordError: '',
      newPasswordError: '',
      confirmPassError: '',
      isNotificationOpen: false,
      isSmsNotiChecked: false,
      isRecommendationsChecked: false,
      isNotificationChecked: false,
      isLogoutDialogopen: false,
      isSmsEnable:false,
      isSmsDisable:false,
      isRecomdEnable:false,
      isRecomdDisable:false,
      isPushEnable:false,
      isPushDisable:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (this.setNewEmailId) {
      if (responseJson.error == "Current Email is incorrect") {
        this.setState({ oldEmailError: responseJson.error })
      }
      if (responseJson.error == "Confirm email doesn't match new email")
        this.setState({ confirmEmailError: "Confirm email doesn't match new email" })
      if (responseJson.message) {
        this.setState({ isEmailSuccess: true })
        this.setState({ isChangeEmailOpen: false, oldEmail: '', newEmail: '', confirmEmail: '' })
      }
    }
    if (this.setNewPassId) {
      if (responseJson.error == 'Current Password is incorrect') { this.setState({ oldPasswordError: 'Current Password is incorrect' }) }
      if (responseJson.error == "Password confirmation doesn't match Password")
        this.setState({ confirmPassError: 'Password does not match' })
      if (responseJson.message) {
        this.navigateEmailLogin()
      }
    }
    this.deleteApi(responseJson)
    this.profileApi(responseJson)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  deleteApi = (responseJson: { message: string; }) => {
    if (this.deleteAccId) {
      if (responseJson.message)
        this.navigateEmailLogin()
    }
  }
  profileApi=(responseJson: { data: { attributes: {allow_sms_notification:boolean,sms_recommendation:boolean }; }; })=>{
    if(this.getProfileDataId){
      const apiData = responseJson.data.attributes
        this.setState({
          isSmsNotiChecked:apiData.allow_sms_notification,
          isRecommendationsChecked:apiData.sms_recommendation
        })

    }
  }
  handleBack = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'JobListing'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleAccountSettingBox = () => {
    this.setState((prevState) => ({
      isAccountOpen: !prevState.isAccountOpen,
    }));
  }
  handleEmailDialog = () => {
    this.setState({ isChangeEmailOpen: true })
  }
  handlePasswordDialog = () => {
    this.setState({ isPasswordChnageOpen: true })
  }
  handleDeleteDialog = () => {
    this.setState({ isDeleteOpen: true })
  }
  closeEmailBox = () => {
    this.setState({ isChangeEmailOpen: false, isPasswordChnageOpen: false, isDeleteOpen: false })
    this.setState({ oldEmail: '', newEmail: '', confirmEmail: '', oldEmailError: '', newEmailError: '', confirmEmailError: '' })
  }
  valueChange = (value: string, event: string) => {
    this.setState({ ...this.state, [value]: event })
  }
  sumbitEmailChange = async () => {
    if (this.validateFields()) {

      const header = {
        token: await storage.get('loginToken'),
      };
      const formData = new FormData();
      formData.append("email", this.state.oldEmail);
      formData.append("new_email", this.state.newEmail);
      formData.append("confirm_email", this.state.confirmEmail);

      const setNewEmail = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.setNewEmailId = setNewEmail.messageId;

      setNewEmail.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_settings/settings/change_email`
      );

      setNewEmail.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      setNewEmail.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      setNewEmail.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );

      runEngine.sendMessage(setNewEmail.id, setNewEmail);

    }
  }
  validateFields = () => {
    const { oldEmail, newEmail, confirmEmail } = this.state;
    let isValid = true;

    if (!oldEmail) {
      this.setState({ oldEmailError: 'This field is required.' });
      isValid = false;
    } else if (!this.emailReg.test(oldEmail)) {
      this.setState({ oldEmailError: 'Invalid Email' })
      isValid = false
    } else this.setState({ oldEmailError: '' })

    if (!newEmail) {
      this.setState({ newEmailError: 'This field is required.' });
      isValid = false;
    }
    else if (!this.emailReg.test(newEmail)) {
      this.setState({ newEmailError: 'Invalid Email' })
      isValid = false
    } else this.setState({ newEmailError: '' })

    if (!confirmEmail) {
      this.setState({ confirmEmailError: 'This field is required.' });
      isValid = false;
    }
    else if (!this.emailReg.test(confirmEmail)) {
      this.setState({ confirmEmailError: 'Invalid Email' })
      isValid = false
    } else this.setState({ confirmEmailError: '' })
    if (oldEmail && newEmail && oldEmail == newEmail) {
      this.setState({ newEmailError: 'New email cannot be the same as the old email.' });
      isValid = false;
    } else {
      this.setState({ newEmailError: '' });
    }
    return isValid;
  }

  handlePasswordChange = (value: string) => {
    this.setState({ oldPassword: value }, () => {
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      isOldPassEnabled: !this.state.isOldPassEnabled,
    });
  }
  handleNewPasswordChange = (value: string) => {
    this.setState({ newPassword: value }, () => {
      const { newPassword } = this.state;
      const passwordConditions = {
        isCapital: /[A-Z]/.test(newPassword),
        isLowercase: /[a-z]/.test(newPassword),
        isNumber: /\d/.test(newPassword),
        isLength: newPassword.length >= 8,
      };
      this.setState({ passwordConditions });
    });
  }
  handleNewClickShowPassword = () => {
    this.setState({
      isNewPassenabled: !this.state.isNewPassenabled,
    });
  }
  submitPasswordChange = async () => {
    if (this.validationPassword()) {

      const header = {
        token: await storage.get('loginToken'),
      };
      const formData = new FormData();
      formData.append("current_password", this.state.oldPassword);
      formData.append("new_password", this.state.newPassword);
      formData.append("password_confirmation", this.state.confirmPassword);

      const setNewEmail = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.setNewPassId = setNewEmail.messageId;

      setNewEmail.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_settings/settings/change_password`
      );

      setNewEmail.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      setNewEmail.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      setNewEmail.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );

      runEngine.sendMessage(setNewEmail.id, setNewEmail);
    }
  }
  validationPassword = () => {
    const { oldPassword, newPassword, confirmPassword, passwordConditions } = this.state;
    let isValid = true;

    if (!oldPassword) {
      this.setState({ oldPasswordError: 'This field is required.' });
      isValid = false;
    } else this.setState({ oldPasswordError: '' })

    if (!newPassword) {
      this.setState({ newPasswordError: 'This field is required.' });
      isValid = false;
    }
    else this.setState({ newPasswordError: '' })

    if (!confirmPassword) {
      this.setState({ confirmPassError: 'This field is required.' });
      isValid = false;
    } else this.setState({ confirmPassError: '' })
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      this.setState({ confirmPassError: 'Passwords do not match.' });
      isValid = false;
    }
    if (newPassword && passwordConditions) {
      if (!passwordConditions.isCapital) {
        this.setState({ newPasswordError: 'Password must contain at least one uppercase letter.' });
        isValid = false;
      } else if (!passwordConditions.isLowercase) {
        this.setState({ newPasswordError: 'Password must contain at least one lowercase letter.' });
        isValid = false;
      } else if (!passwordConditions.isNumber) {
        this.setState({ newPasswordError: 'Password must contain at least one number.' });
        isValid = false;
      } else if (!passwordConditions.isLength) {
        this.setState({ newPasswordError: 'Password must be at least 8 characters long.' });
        isValid = false;
      }
    }
    if (oldPassword && newPassword && oldPassword === newPassword) {
      this.setState({ newPasswordError: 'New password cannot be the same as the old password.' });
      isValid = false;
    } else {
      this.setState({ newPasswordError: '' });
    }

    return isValid;
  }
  handleConfirmwPasswordChange = (value: string) => {
    this.setState({ confirmPassword: value });
  };

  handleConfirmClickShowPassword = () => {
    this.setState({
      isConfirmPassEnabled: !this.state.isConfirmPassEnabled,
    });
  }
  navigateEmailLogin = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  deleteAccount = async () => {

    const header = {
      token: await storage.get('loginToken'),
    };

    const setIntroMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccId = setIntroMsg.messageId;

    const loginId = await storage.get("loginId")

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${loginId}`
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    setIntroMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
  }
  closeSnackbar = () => {
    this.setState({ isEmailSuccess: false })
  }

  handleNotificationBox = () => {
    this.setState((prevState) => ({
      isNotificationOpen: !prevState.isNotificationOpen,
    }));
  }
  handleSmsToggle = async () => {
    this.setState({ isSmsNotiChecked: !this.state.isSmsNotiChecked })
    if(this.state.isSmsNotiChecked){
      this.setState({isSmsEnable:true})
    }else this.setState({isSmsDisable:true})
    const header = {
      token: await storage.get('loginToken')
    };
    
    const formData = new FormData();
    
    formData.append("account[allow_sms_notification]", this.state.isSmsNotiChecked.toString());
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.smsClickedId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/settings/allow_sms_notification`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleRecommendationToggle = async () => {
    this.setState({ isRecommendationsChecked: !this.state.isRecommendationsChecked })
    if(this.state.isRecommendationsChecked){
      this.setState({isRecomdEnable:true})
    }else this.setState({isRecomdDisable:true})
    const header = {
      token: await storage.get('loginToken')
    };
    
    const formData = new FormData();
    formData.append("account[sms_recommendation]", this.state.isRecommendationsChecked.toString());
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
    this.recommonedId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`bx_block_settings/settings/allow_recommendation_notification`);
    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleNotificationToggle = async () => {
    this.setState({ isNotificationChecked: !this.state.isNotificationChecked })
    if(this.state.isNotificationChecked){
      this.setState({isPushEnable:true})
    }else this.setState({isPushDisable:true})
    const header = {
      token: await storage.get('loginToken')
    };
    
    const formData = new FormData();
    
    formData.append("account[allow_notification]", this.state.isNotificationChecked.toString());
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.pushNotiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`bx_block_settings/settings/allow_push_notification`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  navigateTermsAndCondition = () => {
    storage.set('navigateTo', 'Settings2');
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'TermsConditions'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleLogout = () => {
    storage.remove("loginToken")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  handleLogoutClick = () => {
    this.setState({ isLogoutDialogopen: true })
  }
  closeLogout = () => {
    this.setState({ isLogoutDialogopen: false })
  }
  navigatePrivacyFaq = (pageName: string) => {
    storage.set('navigateTo','Settings2')
    const message: Message = new Message(getName(MessageEnum.NavigationMessage)); message.addData( getName(MessageEnum.NavigationTargetMessage), 'PrivacyFaq');
    message.addData(getName(MessageEnum.NavigationPropsMessage), { ...this.props, pageName })
    const raiseMessage: Message = new Message( getName(MessageEnum.NavigationPayLoadMessage) );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { pageName });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  getProfileData = async () => {
    const loginId = await storage.get("loginId")
    const token = await storage.get("loginToken")
    const header = {
      token: token,
    };

    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataId = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/custom_forms/${loginId}/show_profile`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getProfileData();
    storage.remove('naviagteTo')
}
viewNotifications=()=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    'NotificationList'
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}
  // Customizable Area End
}
